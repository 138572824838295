<script setup lang="ts">
  import { computed } from 'vue';

  const variant = defineModel('variant', { type: Object, required: true });

  const props = defineProps({
    product: { type: Object, required: true },
    brand: Object,
    category: Object,
    page: Object,
  });

  const photos = computed(() => {
    return [
      ...variant.value.photos,
      ...props.product.photos,
      ...(props.product.gallery?.photos || []),
    ];
  });
</script>
<template>
  <div class="container product-details-wrapper">
    <div class="gallery-wrapper">
      <SfProductGallery v-bind="{ photos }" />
    </div>
    <div class="details-wrapper">
      <header class="details-header">
        <h1 class="title" v-html="variant.title" />
      </header>
      <div class="details-container">
        <div class="details-left">
          <div class="description" v-if="product.description_short" v-html="product.description_short" />
          <SfStars :count="product.reviews_count" :rating="product.reviews_average" />
          <ShopProductViewMeta :brand :category :product :variant />
        </div>
        <div class="details-right">
          <ShopProductViewTags :product :variant icon />
          <ShopProductViewActions :variant />
          <ShopProductViewPicker :product="product" v-model:variant="variant" />
        </div>
      </div>
      <div v-if="product.files.length > 0" class="details-files">
        <h2>Pliki do pobrania:</h2>
        <ul>
          <li v-for="file in product.files">
            <a :href="route('public.media.file.download', { filename: file.filename })" target="_blank">
              <span class="icon">
                <SfIconDocument />
              </span>
              <span class="name">
                {{ file.display_name || file.original || file.filename }}
              </span>
            </a>
          </li>
        </ul>
      </div>
      <div class="share-with-meta-data-wrapper">
        <ShopProductViewShare :page="page" />
      </div>
    </div>
  </div>
</template>
<style lang="scss">
  :root {
    --sf-product-gallery-view-details-button-width: 3rem;
    --sf-product-gallery-view-details-button-height: 3rem;
    --sf-product-gallery-view-details-button-border: var(--color-light-background);
    --sf-product-gallery-view-details-button-space: 5px;

    --sf-product-gallery-view-details-button-border-radius: 50%;
    --sf-product-gallery-view-details-button-box-shadow: var(--secondary-box-shadow-100);
    --sf-product-gallery-view-details-button-shadow-hover: var(--primary-box-shadow-100);
    --sf-product-gallery-view-details-button-background: var(--color-white);

    --sf-product-gallery-view-details-button-color-hover: var(--color-black);
    --sf-product-gallery-view-details-button-border-hover: var(--color-black);
    --sf-product-gallery-view-details-button-background-hover: var(--color-white);
    --sf-product-gallery-view-details-button-icon-size: 1.5rem;
  }

  .product-gallery .product-gallery__images {
    --sf-carousel-button-width: var(--sf-product-gallery-view-details-button-width);
    --sf-carousel-button-height: var(--sf-product-gallery-view-details-button-height);
    --sf-carousel-button-border: var(--sf-product-gallery-view-details-button-border);
    --sf-carousel-button-space: var(--sf-product-gallery-view-details-button-space);

    --sf-carousel-button-border-radius: var(--sf-product-gallery-view-details-button-border-radius);
    --sf-carousel-button-box-shadow: var(--sf-product-gallery-view-details-button-box-shadow);
    --sf-carousel-button-box-shadow-hover: var(--sf-product-gallery-view-details-button-shadow-hover);
    --sf-carousel-button-background: var(--sf-product-gallery-view-details-button-background);

    --sf-carousel-button-color-hover: var(--sf-product-gallery-view-details-button-color-hover);
    --sf-carousel-button-border-hover: var(--sf-product-gallery-view-details-button-border-hover);
    --sf-carousel-button-background-hover: var(--sf-product-gallery-view-details-button-background-hover);
    --sf-carousel-button-icon-size: var(--sf-product-gallery-view-details-button-icon-size);
  }
</style>
<style scoped lang="scss">
  .details-files {
    h2 {
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }

    ul {
      line-height: 1.5rem;
      list-style: none;
      padding: 0;
      margin: 0;

      a {
        display: flex;
        align-items: flex-start;
        line-height: 1rem;
        font-size: 0.875rem;
        gap: 0.5rem;

        svg {
          display: inline-block;
          font-size: 1rem;
        }
      }
    }
  }

  .details-container {
    display: flex;
    flex-direction: row;
    gap: var(--gap-card);

    .details-left,
    .details-right {
      display: flex;
      flex-direction: column;
      gap: var(--gap-card);
      flex: 1 1 50%;
    }
  }

  .product-details-wrapper {
    display: flex;
    flex-direction: row;
    gap: var(--gap-default);
    margin-bottom: var(--margin-default);

    .gallery-wrapper,
    .details-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: var(--background-card);
      border-radius: var(--border-radius);
      gap: var(--gap-card);
      padding: var(--padding-card);
    }

    .gallery-wrapper {
      position: sticky;
      height: fit-content;
      width: 40%;
    }

    .details-wrapper {
      width: 60%;

      .details-header {
        display: flex;
        flex-direction: column;
        gap: var(--gap-card);

        .title {
          font-size: 1.625rem;
          font-weight: var(--font-weight-bold);
        }
      }

      .add-to-wishlist-wrapper {
        width: fit-content;

        :deep(button) {
          padding-left: 0;
        }
      }

      .share-with-meta-data-wrapper {
        .meta-data-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: var(--gap-card);

          .views-wrapper {
            text-align: end;
          }
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .details-container {
      flex-direction: column;
    }

    .product-details-wrapper {
      .gallery-wrapper {
        width: 45%;
      }

      .details-wrapper {
        width: 55%;
      }
    }
  }

  @media (max-width: 992px) {
    .product-details-wrapper {
      flex-wrap: wrap;
      flex-direction: row;

      .gallery-wrapper {
        position: static;
        width: 100%;
        min-height: 810px;
      }

      .details-wrapper {
        width: 100%;
        max-height: unset;

        .share-with-meta-data-wrapper {
          .meta-data-wrapper {
            flex-direction: column;
            align-items: start;

            .views-wrapper {
              margin-top: 1rem;
              align-self: flex-end;
              font-size: 0.75rem;
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .product-details-wrapper {
      .details-wrapper {
        padding-bottom: 1.25rem;

        &.share-with-meta-data-wrapper {
          gap: 8px;

          .share {
            margin-bottom: 8px;
          }
        }

        .details-description {
          font-size: 0.875rem;
        }
      }

      .gallery-wrapper {
        width: 100%;
        min-height: auto;
      }
    }
  }
</style>
