<script setup lang="ts">
  import { onMounted, ref, computed } from 'vue';
  import { useVisitorForm } from '@plenny/visitor';

  const props = defineProps({
    service: { type: String, required: false, default: undefined },
    type: { type: String, required: false, default: undefined },
  });

  const { data, set, errors } = useVisitorForm();
  const loading = ref(true);
  const map = ref();

  const error = computed(() => {
    let pointId = errors.value.shipping_point_id || [];
    let pointName = errors.value.shipping_point_name || [];

    if (pointId.length > 0) {
      return pointId[0];
    } else if (pointName.length > 0) {
      return pointName[0];
    }

    return undefined;
  });

  function mapService(service) {
    switch (service) {
      case 'inpost':
      case 'paczkomaty':
        return 'inpost';
      case 'dpd':
        return 'dpd';
      case 'ups':
        return 'ups';
      case 'gls':
        return 'gls';
      case 'fedex':
        return 'fedex';
      case 'poczta':
      case 'pocztapolska':
        return 'poczta';
      case 'dhl':
        return 'dhl';
      case 'meest':
        return 'meest';
      case 'orlen':
      case 'ruch':
        return 'orlen';
      default:
        throw new Error('Cannot resolve map widget service.');
    }
  }

  function openMap() {
    map.value = new window.Furgonetka.Map({
      courierServices: [mapService(props.service)],
      type: props.type,
      callback: (params) => {
        set('shipping_point_id', params.point.code);
        set('shipping_point_name', params.point.name);
      },
    });

    map.value.show();
  }

  onMounted(() => {
    if (!window.Furgonetka) {
      const script = document.createElement('script');

      script.src = 'https://furgonetka.pl/js/dist/map/map.js';
      script.async = true;
      script.onload = () => {
        loading.value = false;
      };

      document.head.appendChild(script);
    } else {
      loading.value = false;
    }
  });
</script>
<template>
  <div class="furgonetka-map-widget">
    <SfDataLoader :loading="loading">
      <div class="furgonetka-map-widget__point">
        <div class="furgonetka-map-widget__icon">
          <SfIconPointer />
        </div>
        <div class="furgonetka-map-widget__details">
          <div v-if="data.shipping_point_name" class="furgonetka-map-widget__name">
            {{ data.shipping_point_name }}
          </div>
          <SfButton small @click="openMap" transparent type="button">
            {{ data.shipping_point_name ? 'Zmień punkt' : 'Wybierz punkt' }}
          </SfButton>
        </div>
      </div>
    </SfDataLoader>
    <p v-if="error" class="control__error" v-html="error" />
  </div>
</template>
<style lang="scss">
  .furgonetka-map-widget {
    &__point {
      display: flex;
      align-items: center;
      gap: 1rem;
      border: 1px dashed var(--secondary-border);
      padding: 1rem;
      border-radius: var(--border-radius);
    }

    &__icon {
      font-size: 1.5rem;
      line-height: 1;
    }

    &__details {
      display: flex;
      align-items: center;
      line-height: 1.5rem;
      gap: 0.5rem;
    }

    &__name {
      font-weight: var(--font-weight-bold);
    }

    .control__error {
      color: var(--sf-input-error-color);
      font-size: var(--sf-input-error-font-size);
      padding: var(--sf-input-error-padding-y) var(--sf-input-error-padding-x);
      scroll-margin-top: var(--sf-input-error-scroll-margin-top);
      scroll-margin-bottom: var(--sf-input-error-scroll-margin-bottom);
      margin-top: 1rem;
    }
  }
</style>
