<script setup lang="ts">
  import { computed, type PropType, type Ref } from 'vue';
  import { useShared } from '@plenny/visitor';
  import { UnitFormatter } from '$support';
  import { Unit } from '$types';

  interface Price {
    regular_gross: string;
    regular_net: string;
    price_gross: string;
    price_net: string;
    omnibus_gross: string;
    omnibus_net: string;
    stock_unit: Unit;
    stock_unit_base: string; // DECIMAL(8,4)
    unit_price_unit?: Unit;
    unit_price_default?: Unit;
    unit_price_base?: string; // DECIMAL(8,4)
    unit_price_factor?: 1 | 10 | 100 | 1000;
    unit_price_net?: string; // DECIMAL(19,4)
    unit_price_gross?: string; // DECIMAL(19,4)
  }

  interface Shared {
    include_vat: boolean;
  }

  const props = defineProps({
    price: { type: Object as PropType<Price>, required: true },
  });

  const shared = useShared() as Ref<Shared>;

  const price = computed(() => {
    if (shared.value.include_vat) {
      return props.price.unit_price_gross;
    } else {
      return props.price.unit_price_net;
    }
  });

  const packageUnit = computed(() => {
    let stockUnit = props.price.stock_unit;

    if (stockUnit !== Unit.PACKAGE) {
      return undefined;
    }

    let unit = props.price.unit_price_default;
    let base = props.price.unit_price_base;

    if (unit && base && base !== '1.0000') {
      return UnitFormatter.accusative(unit, base);
    }

    return undefined;
  });

  const unit = computed(() => {
    let defaultUnit = props.price.unit_price_default;
    let factor = props.price.unit_price_factor;

    if (defaultUnit && factor) {
      return UnitFormatter.accusative(defaultUnit, factor);
    }

    return undefined;
  });

  const visible = computed(() => {
    let defaultUnit = props.price.unit_price_default;
    let defaultBase = props.price.unit_price_base;

    if (price.value && unit.value) {
      return (defaultUnit !== Unit.PIECE || defaultBase !== '1.0000');
    }

    return false;
  });
</script>
<template>
  <div v-if="visible && unit && price" class="unit-price">
    <span v-if="packageUnit" class="contains" v-html="$t('W opakowaniu: :packageUnit ', { packageUnit })" />
    <span v-html="$t('Cena za :unit: ', { unit })" />
    <SfMoney :value="price" />
  </div>
</template>
<style lang="scss">
  :root {
    --unit-price-color-text: var(--color-default);
    --unit-price-font-family: var(--font-family-sans);
    --unit-price-font-size: var(--font-size-sm);
    --unit-price-font-weight: var(--font-weight-regular);
    --unit-price-padding-y: 0;
    --unit-price-padding-x: 0;
    --unit-price-margin-y: 0;
    --unit-price-margin-x: 0;
  }
</style>
<style scoped lang="scss">
  .unit-price {
    font-size: var(--unit-price-font-size);
    font-family: var(--unit-price-font-family);
    color: var(--unit-price-color-text);
    font-weight: var(--unit-price-font-weight);
    padding: var(--unit-price-padding-y) var(--unit-price-padding-x);
    margin: var(--unit-price-margin-y) var(--unit-price-margin-x);

    .contains {
      display: block;
    }
  }
</style>
